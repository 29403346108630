import './App.css';

function App() {
  const gif = require('./assets/vid.gif');
  const logo1 = require('./assets/logo1.png');
  const logo2 = require('./assets/logo2.png');
  return (
    <div className="App">
        <img className='video' src={gif} alt="" />
        <a href="https://x.com/WCstud_io" target='_blank'>
        <img className='logo1' src={logo1} alt="" />
        </a>
        <a href="https://linktr.ee/MaverikAgency" target='_bank'>
        <img className="logo2" src={logo2} alt="" />
        </a>
    </div>
  );
}

export default App;
